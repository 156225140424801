import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Table,
  Col,
  Button,
  Form,
  Row,
} from "react-bootstrap";
import LogComponent from "../components/Log/ActionLog";
import SentSMS from "../components/Log/SentSMS";
import ReceivedSMS from "../components/Log/ReceivedSMS";
import NutSentSMS from "../components/Log/SentSMSnut";
import LogService from "../services/consumer_log.service";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../helpers/dateHelper";
import Loading from "../components/Util/Loading";
import Pagination from "../components/Util/Pagination";
import SentNoti from "components/Log/SentNoti";

function Log(props) {
  // if (!props.consumer|| !props.consumer.profile ) {
  //     return <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Эхлээд хэрэглэгчээ хайна уу!</h4>
  // }

  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [mobile, setMobile] = useState("");
  const [smsList, setSMSList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  useEffect(() => {
    setSMSList([]);
    setStartDate(getLastWeekDate());
    setEndDate(getCurrentDate());
    setMobile("");
  }, [props.consumer]);

  const handleSearch = async () => {
    try {
      await searchSMS();
    } catch (err) {}
  };

  const searchSMS = async (offset, limit) => {
    try {
      if (mobile.trim() === "") {
        props.notify("tr", "danger", "Утасны дугаар оруулна уу!");
        return;
      }
      setLoading(true);
      setSMSList([]);
      setPagination(null);
      let data = await LogService.getSMSList(
        mobile.trim(),
        startDate,
        endDate,
        offset,
        limit
      );
      setSMSList(data.data);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid>
        {props?.consumer?.profile ? (
          <Row>
            <LogComponent
              consumer={props.consumer}
              user={props.currentUser?.sub}
            />
          </Row>
        ) : (
          <></>
        )}
        {props?.consumer?.mobile ? (
          <Row>
            <SentSMS consumer={props.consumer} user={props.currentUser?.sub} />
          </Row>
        ) : (
          <></>
        )}
        {props?.consumer?.mobile ? (
          <Row>
            <NutSentSMS
              consumer={props.consumer}
              user={props.currentUser?.sub}
            />
          </Row>
        ) : (
          <></>
        )}
        {props?.consumer?.mobile ? (
          <Row>
            <ReceivedSMS consumer={props.consumer} />
          </Row>
        ) : (
          <></>
        )}
        {props?.consumer?.mobile ? (
          <Row>
            <SentNoti consumer={props.consumer} />
          </Row>
        ) : (
          <></>
        )}
      </Container>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">
              Upoint-д бүртгэлгүй хэрэглэгч рүү илгээсэн мессежүүд{" "}
            </Card.Title>
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Эхлэх огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Дуусах огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    placeholder={endDate || "дуусах огноо"}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Дугаар</Form.Label>
                  <Form.Control
                    type="text"
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                  >
                    Хайх
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr style={{ wordBreak: "break-word" }}>
                  <th className="border-0">Огноо</th>
                  <th className="border-0">Дугаар</th>
                  <th className="border-0">Мессеж текст</th>
                  <th className="border-0">Үр дүн</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {smsList.map((sms, key) => (
                  <tr key={key}>
                    <td>{formatPretty(sms.created_at)}</td>
                    <td>{sms.mobile}</td>
                    <td>{sms.text}</td>
                    <td style={sms.result ? styleGreen : styleRed}>
                      {sms.result ? "Амжилттай" : "Амжилтгүй"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={searchSMS} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default Log;
